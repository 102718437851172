import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { auth, loginWithEmailAndPassword } from "../../firebase.js"
import { useAuthState } from "react-firebase-hooks/auth"

import "../../css/Auth.css"

function Login() {

        const [email, setEmail] = useState("")
        const [password, setPassword] = useState("")
        const [user, loading, error] = useAuthState(auth)
        const navigate = useNavigate();

        useEffect(() => {
                if (loading) {
                        return
                }
                if (user) {
                        navigate("/");
                }
        }, [user, loading])

        return (
                <div 
                        className="container-fluid"
                        style={{
                                padding: "0",
                                minHeight: "100vh",
                                backgroundColor: "#6b9ce7"
                        }}
                >

                        <div className="notification"></div>

                        <div className="row" style={{ minHeight: "100vh" }}>

                                <div className="col-12 d-flex justify-content-center align-items-center">

                                        <div className="Auth__box">

                                        <h1 className="Auth__text-center">Login</h1>

                                                <div className="Auth__input-container">
                                                        <input
                                                                type="text"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                        />
                                                        <label>E-mail Address</label>
                                                </div>
                                                <div className="Auth__input-container">
                                                        <input
                                                                type="password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                required
                                                        />
                                                        <label>Password</label>
                                                </div>
                                                <button
                                                        className="Auth__btn"
                                                        onClick={() => loginWithEmailAndPassword(email, password)}
                                                >
                                                        Login
                                                </button>
                                                <div style={{ paddingTop: "30px" }}>
                                                        <Link to="/reset">Forgot Password</Link>
                                                </div>
                                                <div style={{ color: "white" }}>
                                                        Don't have an account? <Link to="/register">Register</Link> now.
                                                </div>

                                        </div>

                                </div>

                        </div>

                </div>
        )

}

export default Login