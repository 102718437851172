import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";
import {
        getAuth,
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        sendPasswordResetEmail,
        signOut,
} from "firebase/auth"
import {
        getFirestore,
        doc,
        setDoc,
} from "firebase/firestore"

function showNotification(message) {
        const notification = document.getElementsByClassName("notification")[0];
        if (notification) {
            notification.textContent = message;
            notification.classList.add("animated");
    
            // Automatically hide notification after 5 seconds
            setTimeout(() => {
                notification.classList.remove("animated");
                notification.style.top = "-900px"; // Reset position
            }, 5000);
        } else {
            console.error("Notification element not found");
        }
    }

const firebaseConfig = {
        apiKey: "AIzaSyCXp9s1vBlm1HnAf4tC83g5AbfabBy0F2U",
        authDomain: "bluebird-documentation.firebaseapp.com",
        projectId: "bluebird-documentation",
        storageBucket: "bluebird-documentation.appspot.com",
        messagingSenderId: "65293049615",
        appId: "1:65293049615:web:077b2757723651e3cda87a",
        measurementId: "G-0Z24RWEJ2Y"
};

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);
const auth = getAuth(app)
const db = getFirestore(app)

const loginWithEmailAndPassword = async (email, password) => {
        try {
                await signInWithEmailAndPassword(auth, email, password)        
        } catch (err) {
                showNotification(err.message)
        }
}

const registerWithEmailAndPassword = async (name, email, password) => {
        try {
                const res = await createUserWithEmailAndPassword(auth, email, password)
                const user = res.user
                await setDoc(doc(db, "users", user.uid), {
                        uid: user.uid,
                        name,
                        authProvider: "local",
                        email
                })
                return user.uid
        } catch (err) {
                showNotification(err)
                return
        }
}

const sendPasswordReset = async (email) => {
        try {
                await sendPasswordResetEmail(auth, email)
                showNotification("Password reset link sent!")
        } catch(err) {
                showNotification(err)
        }
}

const logout = () => { signOut(auth) }

export {
        auth,
        db,
        loginWithEmailAndPassword,
        registerWithEmailAndPassword,
        sendPasswordReset,
        logout,
        showNotification,
}