import React, { useState, useEffect } from "react";

import "../../css/DocumentationMainPage.css"
import "../../css/Create.css"
import DocumentationHomeComponent from "./DocumentationHomeComponent";
import Sidebar from "../shared/Sidebar";

function DocumentationMainPage() {

        return (
                <div className="DocumentationMainPage__div">
                        <Sidebar />

                        <div className="DocumentationMainPage__main-content">
                                <DocumentationHomeComponent />
                        </div>
                </div>
        )

}

export default DocumentationMainPage