import React, { useState, useEffect } from "react";
import "../../css/Account.css"
import Header from "../shared/Header"
import { useParams, useNavigate } from "react-router-dom";

function Account() {

        const { uid } = useParams()
        const [accountEmail, setAccountEmail] = useState("")
        const [accountName, setAccountName] = useState("")
        const [accountCreatedDate, setAccountCreatedDate] = useState("")
        const [accountLastLoginDate, setAccountLastLoginDate] = useState("")

        useEffect(() => {
                if (uid) {
                        fetchAccountDetails()
                }
        }, [uid])

        const formatTimestamp = (timestamp) => {
                const date = new Date(timestamp);
                return date.toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                });
        };

        const fetchAccountDetails = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/${uid}/`)
                        const data = await response.json()
                        setAccountEmail(data.email)
                        setAccountName(data.name)
                        setAccountCreatedDate(formatTimestamp(data.created_at))
                        setAccountLastLoginDate(formatTimestamp(data.last_login))
                } catch (error) {
                        console.error("Error in fetching account details: ", error)
                }
        }

        const saveAccountName = async () => {
                try {
                        const data = {
                                name: accountName
                        }

                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/update/${uid}/`, {
                                method: "PATCH",
                                headers: {"Content-Type": "application/json"},
                                body: JSON.stringify(data)
                        })

                        if (response.ok) {
                                alert("Account name changed successfully!")
                        } else {
                                alert("Failed to update account name.")
                        }
                } catch (error) {
                        alert("Failed to change account name: ", error)
                }
        }

        return (
                <div>
                        <Header />
                        <div className="Account__title-section">
                                <h1>Account Settings</h1>
                        </div>
                        <div className="Account__main-content">

                                <h2>Account Details</h2>

                                <p className="small" style={{ paddingTop: "30px" }}>Account Created on: {accountCreatedDate}</p>
                                <p className="small" style={{ paddingBottom: "30px" }}>Last Logged in: {accountLastLoginDate}</p>

                                <label>Your Displayed Name: </label>
                                <input
                                        id="accountNameInput"
                                        type="text"
                                        value={accountName}
                                        onChange={(e) => setAccountName(e.target.value)}
                                        onBlur={saveAccountName}
                                        className="Account__edit-input"
                                />
                                <p>Email Address: <span title="As of the current release, account emails can't be changed. :(" className="Account__read-only-field">{accountEmail}</span></p>

                        </div>
                </div>
        )

}

export default Account