import React, { useState, useRef } from "react";
import "../../css/DocumentationMainPage.css"
import Sidebar from "../shared/Sidebar.js";
import "../../css/Create.css"
import "../../css/Help.css"
import { useNavigate, Link } from "react-router-dom"
import "../../css/SupportTicket.css"
import "../../css/Document.css"

function SupportTicket() {

        const [email, setEmail] = useState("")
        const [shortDescription, setShortDescription] = useState("")
        const [message, setMessage] = useState("")
        const [uploadedFiles, setUploadedFiles] = useState([]);
        const fileInputRef = useRef(null);

        const handleFileChange = (e) => {
                const files = Array.from(e.target.files);
                const maxFileSize = 8 * 1024 * 1024; 
                const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
        
                let validFiles = files.filter(file => {
                        if (file.size > maxFileSize) {
                                alert(`File ${file.name} exceeds the 8MB limit.`);
                                return false;
                        }
                        if (!allowedFileTypes.includes(file.type)) {
                                alert(`File ${file.name} is not a valid type. Only PDFs, JPEG, and PNG files are allowed.`);
                                return false;
                        }
                        return true;
                });
        
                if (uploadedFiles.length + validFiles.length > 2) {
                        alert("You can only upload up to two files.");
                        validFiles = validFiles.slice(0, 2 - uploadedFiles.length);
                }
        
                setUploadedFiles([...uploadedFiles, ...validFiles]);
        };
        
        const handleFileRemove = (index) => {
                const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
                setUploadedFiles(updatedFiles);
        };

        const triggerFileInput = () => {
                fileInputRef.current.click();
        };

        const handleSubmit = async () => {

                const formData = new FormData();
                formData.append("email", email);
                formData.append("shortDescription", shortDescription);
                formData.append("message", message);
                uploadedFiles.forEach((file, index) => {
                    formData.append(`file${index + 1}`, file);
                });

                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/support/ticket/", {
                                method: "POST",
                                body: formData
                        })

                        if (response.ok) {
                                console.log("Successfully submitted ticket.")
                        } else {
                                console.log("Failed to submit ticket.")
                        }
                } catch (error) {
                        console.error("Error in submitting ticket: ", error)
                }

        }

        return (
                <div className="DocumentationMainPage__div">
                        <Sidebar />

                        <div className="DocumentationMainPage__main-content">

                                <div className="Document__div">

                                        <p>Please use the form below to submit a support ticket.</p>

                                        <p className="small">
                                                <strong>Note: </strong> This is not a space to suggest features, ask non-technical questions, etc. If 
                                                that is what you're looking for, please use the <Link to="/help">General Contact Form</Link>.
                                        </p>

                                        <p className="small">
                                                While we try to log errors that users might encounter, it's possible that some might be 
                                                missed. When submitting a support ticket, try to be as detailed as possible, and try to use 
                                                multiple forms of evidence. Below are some suggestions as to what to include.
                                        </p>
                                        <ul>
                                                <li>Upload screenshots</li>
                                                <li>Explain in detail what is happening</li>
                                                <ul>
                                                        <li>
                                                                <strong>Which feature is the unexpected behavior involved with?</strong> Features 
                                                                would include creating, deleting, reading, or modify documents, or could be something 
                                                                else like permissions for other users. <strong>You can use the short description field below to 
                                                                quickly mention the feature involved</strong>.
                                                        </li>
                                                        <li>Did this unexpected behavior occur only once?</li>
                                                        <li>
                                                                If this occurs repeatedly, what are the steps you take to 
                                                                reproduce the issue?
                                                        </li>
                                                        <li>Any additional notes that might come to mind.</li>
                                                </ul>
                                        </ul>

                                        <div className="SupportTicket__form">

                                                <h2 style={{ marginBottom: "50px" }}>Support Ticket Form</h2>

                                                <div className="SupportTicket__input-container">
                                                        <input
                                                                type="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                        />
                                                        <label>E-mail Address</label>
                                                </div>

                                                <div className="SupportTicket__input-container">
                                                        <input
                                                                type="text"
                                                                value={shortDescription}
                                                                onChange={(e) => setShortDescription(e.target.value)}
                                                                required
                                                        />
                                                        <label>Short Description</label>
                                                </div>

                                                <div className="SupportTicket__file-upload-input-container">
                                                        {/* <label>Upload Files (Max: 2 files, up to 8MB each):</label> */}
                                                        <button
                                                                type="button"
                                                                onClick={triggerFileInput}
                                                                className="SupportTicket__file-upload-input"
                                                        >
                                                                Choose Files
                                                        </button>
                                                        <input
                                                                type="file"
                                                                multiple
                                                                accept=".pdf, image/jpeg, image/png"
                                                                onChange={handleFileChange}
                                                                ref={fileInputRef}
                                                                style={{ display: "none" }}
                                                        />
                                                        <div>
                                                                {uploadedFiles.map((file, index) => (
                                                                        <div key={index}>
                                                                        {file.name} 
                                                                        <button style={{ marginLeft: "10px", marginTop: "10px" }} className="SupportTicket__file-upload-input" type="button" onClick={() => handleFileRemove(index)}>Remove</button>
                                                                        </div>
                                                                ))}
                                                        </div>
                                                </div>

                                                <div className="SupportTicket__text-area-input-container">
                                                        <label>Detailed Description</label>
                                                        <textarea
                                                                value={message}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                                rows={7}
                                                                required
                                                        ></textarea>
                                                </div>

                                                <button onClick={handleSubmit} className="SupportTicket__file-upload-input">Submit</button>

                                        </div>

                                </div>

                        </div>

                </div>
        )

}

export default SupportTicket