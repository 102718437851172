import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import "firebase/auth"
import { auth, logout, db } from "../../firebase.js"

import "../../css/Header.css"

function Header() {

        const [menuOpen, setMenuOpen] = useState(false)
        const [loggedIn, setLoggedIn ] = useState(false)
        const [currentUserUid, setCurrentUserUid] = useState("")

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setCurrentUserUid(user.uid)
                                setLoggedIn(true)
                        } else {
                                setCurrentUserUid(null)
                                setLoggedIn(false)
                        }
                })

                return () => { unsubscribe() }
        }, [])

        const handleLogout = () => { logout() }

        const toggleMenu = () => { setMenuOpen(!menuOpen) }

        return (
                <div className="Header__navbar">

                        <div className="Header__navbar-logo">
                                <Link to="/">
                                        <img 
                                                style={{ marginBottom: "0px" }}
                                                src={"https://i.ibb.co/1GqfVv1/Blue-Bird-Logo-Color-Light-Text.png"}
                                                alt="Logo"
                                                width={130}
                                                height={100}
                                        />
                                </Link>
                        </div>

                        <div class={`Header__navbar-buttons ${menuOpen ? "open" : ""}`}>
                                {loggedIn ? (
                                        <>
                                                <Link to="/documentation">
                                                        <button type="button" className="button Header__buttons">
                                                                Documentation
                                                        </button>
                                                </Link>
                                                <Link to="/help">
                                                        <button type="button" className="button Header__buttons">
                                                                Help
                                                        </button>
                                                </Link>
                                                <Link to={`/account/${currentUserUid}`}>
                                                        <button type="button" className="button Header__buttons">Account</button>
                                                </Link>
                                                <button type="button" className="button logout Header__logout-button" onClick={handleLogout}>
                                                        Logout
                                                </button>
                                        </>
                                ) : (
                                        <Link to="/login">
                                                <button type="button" className="button login-button Header__login-button">
                                                        Login
                                                </button>
                                        </Link>
                                )}
                        </div>

                        <div className="Header__navbar-menu-icon" onClick={toggleMenu}>
                                <i class="fa-solid fa-bars" style={{ color: "white", fontSize: "21px" }}></i>
                        </div>

                </div>
        )

}

export default Header