import "../../css/Search.css"
import Select from 'react-select';
import { auth } from "../../firebase.js";
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import "../../css/DocumentationMainPage.css"

function Search() {

        const [loading, setLoading] = useState(true)
        const [userUid, setUserUid] = useState("")
        const [userEmail, setUserEmail] = useState("")
        const [publicDocumentationResponseData, setPublicDocumentationResponseData] = useState([])

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                                setUserUid(user.uid);
                                setUserEmail(user.email);
                        } else {
                                setUserUid(null);
                                setUserEmail(null);
                                setLoading(false)
                        }
                });
                return () => { unsubscribe(); };
        }, []);

        useEffect(() => {
                if (userUid) {
                        fetchPublicDocumentation()
                }
        }, [userUid])

        const fetchPublicDocumentation = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/public-documentation/`, {
                                method: 'GET',
                                headers: {
                                        "Content-Type": "application/json" ,
                                }
                        })

                        setLoading(false)
                        const data = await response.json()
                        const options = data.map((doc) => ({
                                value: {
                                        type: doc.type || "unknown",
                                        document_name: doc.name || doc.page_name,
                                        author: doc.author,
                                },
                                label: (
                                        <span className="Search__truncated-text">
                                                {doc.name || doc.page_name} - {doc.author_name} - {doc.description || "No description"}
                                        </span>
                                ),
                        }));
                        setPublicDocumentationResponseData(options)
                } catch (error) {
                        setLoading(false)
                        console.error("Error in fetching public documentation: ", error)
                }
        }

        if (loading) {
                return (
                        <div className="DocumentationHomeComponent__loading-animation-div">
                                <i className="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i>
                        </div>
                )
        }

        return (
                <div className="DocumentationMainPage__div">

                        <Sidebar />

                        <div className="DocumentationMainPage__main-content">

                                <h2>Public Documentation</h2>

                                <p>Search for public spaces and pages below.</p>

                                <Select
                                        options={publicDocumentationResponseData}
                                        placeholder="Search"
                                        isSearchable={true}
                                        onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                        const { type, document_name, author } = selectedOption.value;
                                                        const url = `https://bluebird-documentation.com/documentation/${type}/${document_name}/${author}/`;
                                                        window.open(url, "_blank");
                                                }
                                        }}
                                />

                                <p className="small" style={{ marginTop: "20px" }}>
                                        As of this current version, this search feature is pretty basic. You'll be able to search by the document name, or you can scroll 
                                        through to find the document you're interested in.
                                </p>

                                <p className="small">
                                        As of now, there are no categories for different content areas, but that is definitely something we hope to introduce soon to the search feature!
                                </p>

                                <p className="extra-small">
                                        Thank you for your patience, and please let us know what else you would like to see with the search feature! 
                                </p>

                        </div>

                </div>
        )

}

export default Search