import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase.js";
import { useNavigate } from "react-router-dom"

import "../../css/Sidebar.css";

function Sidebar() {
        const [collapsed, setCollapsed] = useState(() => {
                return window.innerWidth > 600;
        });
    const [pageDetails, setPageDetails] = useState([]);
    const [pageDetailsForSharedDocuments, setPageDetailsForSharedDocuments] = useState([])
    const [loading, setLoading] = useState(true);
    const [uid, setUid] = useState("");
    const [userEmail, setUserEamil] = useState("")
    const [hasDocumentation, setHasDocumentation] = useState(true);
    const [viewingSharedDocuments, setViewingSharedDocuments] = useState(false)
    const [hasSharedDocumentation, setHasSharedDocumentation] = useState(true)
    const navigate = useNavigate();

    const toggleSidebar = () => { setCollapsed(!collapsed); };

    const handleNavigateClick = () => { navigate("/create") }

    const handleResize = () => {
        setCollapsed(window.innerWidth > 600);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUid(user.uid);
                setUserEamil(user.email)
            } else {
                setUid(null);
                setUserEamil(null)
            }
        });

        return () => { unsubscribe(); };
    }, []);

    useEffect(() => {
        const fetchPageDetails = async () => {
            if (!uid) return;
            try {
                const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/page-details/author/${uid}/`);
                
                if (response.status === 404) {
                    setHasDocumentation(false);
                    setLoading(false);
                    return;
                }

                const data = await response.json();
                organizePageData(data);
                setLoading(false);
                setHasDocumentation(true);
            } catch (error) {
                console.error("Error fetching page details:", error);
            }
        };

        fetchPageDetails();
    }, [uid]);

        const fetchPageDetailsForSharedDocuments = async () => {
                if (!uid) return
                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/documentation/shared-documents/", {
                                method: "GET",
                                headers: {
                                        'requesting-user-uid': uid,
                                        'requesting-user-email': userEmail,
                                }
                        })

                        if (response.status === 404) {
                                setHasSharedDocumentation(false);
                                return;
                        }

                        const data = await response.json()
                        const isEmpty = Array.isArray(data)
                                ? data.length === 0 
                                : Object.keys(data).length === 0; 
                        if (isEmpty) {
                                setHasSharedDocumentation(false)
                                return
                        }
                        organizePageDataForSharedDocuments(data)
                        setHasSharedDocumentation(true)
                } catch (error) {
                        console.error("Error fetching page details for shared documents: ", error)
                }
        }

    const handleHelpClick = () => { navigate("/help") }

    const organizePageDataForSharedDocuments = (data) => {
        const organizedData = {};
        const orphanPages = []; // Store pages with missing parents temporarily
    
        // First pass: Create entries for spaces and any pages that don't have a parent
        data.forEach((item) => {
            if (!item.parent_page) {
                // This is a top-level space or page
                organizedData[item.space || item.page_name] = { ...item, children: [] };
            } else {
                // If it has a parent_page, assume it's an orphan for now
                orphanPages.push(item);
            }
        });
    
        // Recursive helper function to find the correct parent and add the item
        const findParentAndAddChild = (parentName, child) => {
            // Loop through organizedData to find the parent recursively
            for (const key in organizedData) {
                const result = addChildToParent(organizedData[key], parentName, child);
                if (result) return true;
            }
            return false;
        };
    
        const addChildToParent = (node, parentName, child) => {
            if (node.page_name === parentName || node.space === parentName) {
                // If this node is the parent, add the child here
                node.children.push({ ...child, children: [] });
                return true;
            }
            // Otherwise, recursively check each child node
            for (const subNode of node.children) {
                const found = addChildToParent(subNode, parentName, child);
                if (found) return true;
            }
            return false;
        };
    
        // Second pass: Place each page under its parent or treat it as a top-level page if the parent is missing
        orphanPages.forEach((item) => {
            if (!findParentAndAddChild(item.parent_page, item)) {
                // If the parent is not found, treat it as a top-level page
                organizedData[item.page_name] = { ...item, children: [] };
            }
        });
    
        // Convert the organizedData to an array and set the state
        console.log("Organized Shared Data: ", organizedData);
        setPageDetailsForSharedDocuments(Object.values(organizedData));
    };    

    const organizePageData = (data) => {
        const organizedData = {};
    
        // First pass: Create entries for spaces and any pages that don't have a parent
        data.forEach(item => {
            if (!item.parent_page) {
                // This is a top-level space
                organizedData[item.space || item.page_name] = { ...item, children: [] };
            }
        });
    
        // Recursive helper function to find the correct parent and add the item
        const findParentAndAddChild = (parentName, child) => {
            // Loop through organizedData to find the parent recursively
            for (const key in organizedData) {
                const result = addChildToParent(organizedData[key], parentName, child);
                if (result) return true;
            }
            return false;
        };
    
        const addChildToParent = (node, parentName, child) => {
            if (node.page_name === parentName || node.space === parentName) {
                // If this node is the parent, add the child here
                node.children.push({ ...child, children: [] });
                return true;
            }
            // Otherwise, recursively check each child node
            for (const subNode of node.children) {
                const found = addChildToParent(subNode, parentName, child);
                if (found) return true;
            }
            return false;
        };
    
        // Second pass: Place each page under its parent
        data.forEach(item => {
            if (item.parent_page) {
                // If we have a parent_page, try to place this item under its parent
                findParentAndAddChild(item.parent_page, item);
            }
        });
    
        // Convert the organizedData to an array and set the state
        setPageDetails(Object.values(organizedData));
    };

    const renderPages = (pages) => {
        return (
            <ul>
                {pages.map((page, idx) => (
                    <PageItem key={idx} page={page} uid={uid} />
                ))}
            </ul>
        );
    };    

        const renderSharedPages = (pages, documentUid) => {
                return (
                        <ul>
                                {pages.map((page, idx) => (
                                        <PageItem key={idx} page={page} uid={documentUid} />
                                ))}
                        </ul>
                )
        }

        const handleUsersIconClick = () => {
                const userIcon = document.getElementById("user-icon")
                userIcon.style.borderBottom = "1px solid #090E16"
                const usersIcon = document.getElementById("users-icon")
                usersIcon.style.borderBottom = "1px solid white"
                setViewingSharedDocuments(true)
                fetchPageDetailsForSharedDocuments()
        }

        const handleUserIconClick = () => {
                const userIcon = document.getElementById("user-icon")
                userIcon.style.borderBottom = "1px solid white"
                const usersIcon = document.getElementById("users-icon")
                usersIcon.style.borderBottom = "1px solid #090E16"
                setViewingSharedDocuments(false)
        }

        const handleSearchClick = () => { navigate("/documentation/search") }

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                <div className="Sidebar__search-icon-container">
                        <button onClick={handleSearchClick} className="Sidebar__search-button"><i class="fa-solid fa-magnifying-glass Sidebar__search-icon"></i>Search</button>
                </div>
            <button onClick={toggleSidebar} className="toggle-button">
                <i className={`fas ${collapsed ? 'fa-arrow-left' : 'fa-arrow-right'}`}></i>
            </button>
            <div className={`Sidebar__icon-container ${collapsed ? 'Sidebar__icon-container': 'Sidebar__hide-content'}`}>
                <Link to="/">
                    <i className="fas fa-home Sidebar__icons" title="Home" style={{ marginRight: "25px" }}></i>
                </Link>
                <i id="user-icon" onClick={handleUserIconClick} className="fa-solid fa-user Sidebar__icons" title="View your documents." style={{ marginRight: "25px", borderBottom: "1px solid white" }}></i>
                <i id="users-icon" onClick={handleUsersIconClick} className="fa-solid fa-users Sidebar__icons" title="View documents shared with you."></i>
            </div>

            {loading ? (
                <div>Loading...</div>
            ) : (
                <nav className={`${collapsed ? '': 'Sidebar__hide-content'}`}>
                    {hasDocumentation && viewingSharedDocuments !== true ? (
                        <ul className="Sidebar__ul">
                        {pageDetails.map((space, index) => (
                                <li key={index}>
                                <Link key={`${space.page_name || space.space}-${space.document_uid}`} to={`/documentation/${space.page_name ? 'page' : 'space'}/${space.space}/${uid}`}>{space.space}</Link>
                                {space.children.length > 0 && renderPages(space.children)}
                                </li>
                        ))}
                        </ul>
                    ) : hasSharedDocumentation && viewingSharedDocuments ? (
                        <ul className="Sidebar__ul">
                        {pageDetailsForSharedDocuments.map((item, index) => (
                                <li key={index}>
                                <Link
                                        key={`${item.page_name || item.space}-${item.document_uid}`}
                                        to={`/documentation/${item.page_name ? 'page' : 'space'}/${item.page_name || item.space}/${item.document_uid}`}
                                >
                                        {item.page_name || item.space}
                                </Link>
                                {item.children.length > 0 && renderSharedPages(item.children, item.document_uid)}
                                </li>
                        ))}
                        </ul>
                    ) : viewingSharedDocuments && hasSharedDocumentation !== true ? (
                        <p style={{ padding: "10px", fontSize: "16px" }}>No shared documentation to show.</p>
                    ) : (
                        <p style={{ padding: "10px", fontSize: "16px" }}>No documentation to show.</p>
                    )}
                </nav>
            )}

        {collapsed && (
                <>
                        <button className="Sidebar__create-button" onClick={handleNavigateClick}>
                                <i className="fas fa-plus Sidebar__plus-sign"></i>&nbsp; Create
                        </button>
                        <i class="fa-solid fa-circle-question Sidebar__help" onClick={handleHelpClick} title="Help"></i>
                </>
        )}
        </div>
    );
}

function PageItem({ page, uid }) {
        const [expanded, setExpanded] = useState(false);
    
        const toggleExpanded = () => setExpanded(!expanded);
    
        return (
            <li style={{ listStyleType: page.children && page.children.length > 0 ? 'none' : 'disc' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Show the caret if the page has children, otherwise no icon */}
                    {page.children && page.children.length > 0 && (
                        <button onClick={toggleExpanded} style={{ marginRight: '5px', background: 'none', border: 'none', cursor: 'pointer', color: '#fff' }}>
                            <i className={`Sidebar__icon-size fas ${expanded ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
                        </button>
                    )}
                        <Link to={`/documentation/${page.page_name ? 'page' : 'space'}/${page.page_name || page.space}/${uid}`}>
                                <p className="Sidebar__link">{page.page_name || page.space}</p>
                        </Link>
                </div>
                {expanded && page.children && page.children.length > 0 && (
                    <ul style={{ paddingLeft: '20px' }}>
                        {page.children.map((child, idx) => (
                            <span className="Sidebar__text"><PageItem key={idx} page={child} uid={uid} type={page.page_name ? 'page' : 'space'} /></span>
                        ))}
                    </ul>
                )}
            </li>
        );
    }    
 
export default Sidebar;
