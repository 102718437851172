import React, { useState, useEffect } from "react";
import { auth } from "../../firebase.js"
import { useNavigate } from "react-router-dom"
import ReactQuill from 'react-quill';
import Quill from "quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import Select from 'react-select';
import 'quill-table-ui/dist/index.css';
import QuillTableUI from 'quill-table-ui';

import "../../css/Create.css"
import Sidebar from "../shared/Sidebar.js";

Quill.register('modules/imageResize', ImageResize);
const Inline = Quill.import('blots/inline');

class InlineCode extends Inline {
    static create() {
        const node = super.create();
        node.setAttribute('class', 'inline-code');
        return node;
    }

    static formats(node) {
        return true;
    }
}

InlineCode.blotName = 'inline-code';
InlineCode.tagName = 'code';

Quill.register(InlineCode);

function Create() {

        const [selectedType, setSelectedType] = useState("")
        const [isPublic, setIsPublic] = useState(false)
        const [emails, setEmails] = useState([]); 
        const [content, setContent] = useState("");
        const [uid, setUid] = useState("")
        const [name, setName] = useState("")
        const [allDocumentation, setAllDocumentation] = useState([])
        const [selectedSpace, setSelectedSpace] = useState("")
        const [selectedParent, setSelectedParent] = useState("")
        const [completed, setCompleted] = useState(false)
        const [description, setDescription] = useState("")
        const [currentEmail, setCurrentEmail] = useState("")
        const [hasSpaces, setHasSpaces] = useState(false)
        const navigate = useNavigate();

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setUid(user.uid)
                        } else {
                                setUid(null)
                        }
                })

                return () => { unsubscribe() }
        }, [])

        useEffect(() => { 
                if (uid) {
                        fetchPageDetails() 
                }
        }, [uid])

        useEffect(() => {
                if (selectedType === "Page") {
                        populateDocumentation();
                }
        }, [selectedType]);
            
        const populateDocumentation = async () => {
                try {
                        const [spaceResponse, pageResponse] = await Promise.all([
                                fetch("https://bluebirddocumentationadmin.pythonanywhere.com/spaces/author/" + uid + "/"),
                                fetch("https://bluebirddocumentationadmin.pythonanywhere.com/pages/author/" + uid + "/")
                        ]);
            
                        const spaceData = spaceResponse.status === 200 ? await spaceResponse.json() : { spaces: [] };
                        const pageData = pageResponse.status === 200 || pageResponse.status === 404 ? await pageResponse.json() : [];
                        const spaceOptions = Array.isArray(spaceData.spaces) ? spaceData.spaces.map(space => ({ label: space.name, value: space.id })) : [];
                        const pageOptions = Array.isArray(pageData.pages) ? pageData.pages.map(page => ({ label: page.page_name, value: page.id })) : [];
                
                        setAllDocumentation([...spaceOptions, ...pageOptions]);
                } catch (error) {
                        console.error("Error fetching data:", error);
                }
        };
            
        const fetchPageDetails = async () => {
                try {
                        if (uid.length !== 0) {
                                const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/page-details/author/" + uid + "/", {
                                        method: "GET",
                                        headers: { "Content-Type": "application/json" }
                                });
                    
                                if (response.status === 200) {
                                        console.log("Successfully fetched data from page details");
                                        setHasSpaces(true)
                                } else if (response.status === 404) {
                                        console.log("No page detail data found for this author.")
                                        setHasSpaces(false)
                                } else {
                                        console.log("Failed to fetch page detail data.");
                                }
                        }

                } catch (error) {
                        console.error("Error getting data from page detail table:", error);
                }
        }

        const maxLengths = {
                Space: 100,
                Page: 150,
        };

        const currentMaxLength = maxLengths[selectedType] || 100;

        const handleToggle = (e) => { setIsPublic(e.target.checked) }
        const handleCompletedToggle = (e) => { setCompleted(e.target.checked) }
        const handleSelectedType = (e) => { setSelectedType(e.target.value) }

        const handleAddEmail = () => {
                if (currentEmail && !emails.some(item => item.email === currentEmail)) {
                        setEmails([...emails, { email: currentEmail, permission: "Can Edit" }]);
                        setCurrentEmail("");
                } else {
                        alert("This email has already been added.");
                }
        };

        const handleRemoveEmail = (index) => {
                const updatedEmails = [...emails];
                updatedEmails.splice(index, 1);
                setEmails(updatedEmails);
        };            

        const handlePermissionChange = (index, newPermission) => {
                const updatedEmails = [...emails];
                updatedEmails[index].permission = newPermission;
                setEmails(updatedEmails); 
        };

        const modules = {
                toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }],   
                        [{ 'font': [] }, { 'size': [] }],    
                        ['bold', 'italic', 'underline', 'strike'],   
                        [{ 'color': [] }, { 'background': [] }],    
                        [{ 'script': 'sub' }, { 'script': 'super' }],     
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],   
                        [{ 'indent': '-1' }, { 'indent': '+1' }],     
                        [{ 'align': [] }],   
                        ['blockquote', 'code-block'],   
                        ['link', 'image', 'video'],
                        ['inline-code'],
                        ['formula'], 
                        ['clean']  
                ],
                imageResize: {
                        parchment: Quill.import('parchment'),
                        modules: ['Resize', 'DisplaySize']
                }
        };

        const createPermissionTableEntry = async () => {
                const data = {
                        name: name,
                        documentationType: selectedType,
                        userEmail: emails,
                        isPublic: isPublic,
                        documentUid: uid,
                        documentUrl: `documentation/${selectedType.toLowerCase()}/${name}/${uid}/`,
                        description: description
                }

                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/permissions/create/", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(data),
                        })

                        if (response.ok) {
                                console.log("Successfully set permissions.")
                        } else {
                                console.log("Failed to create permissions.")
                        }
                } catch (error) {
                        console.error("Error submitting the form:", error);
                }
        }

        const handleSubmit = async () => {

                if (selectedType === "Page" && !selectedParent) {
                        alert("Please select a parent for the page.");
                        return;
                }

                if (emails.length > 0) { createPermissionTableEntry() }

                const data = {
                        type: selectedType,
                        isPublic: isPublic,
                        emails: emails,
                        content: content,
                        name: name,
                        author: uid,
                        completed: completed,
                        description: description,
                        space: selectedSpace,
                        parentPage: selectedParent,
                };
            
                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/documentation/create/", {
                                method: "POST",
                                headers: {
                                        "Content-Type": "application/json",
                                },
                                body: JSON.stringify(data),
                        });
            
                        if (response.ok) {
                                console.log("Successfully submitted the form!");
                        } else {
                                console.log("Failed to submit the form.");
                        }
                } catch (error) {
                        console.error("Error submitting the form:", error);
                }

                navigate(`/documentation/${selectedType.toLowerCase()}/${name}/${uid}/`);
        };

        return (
                <div className="Create__div">
                        <Sidebar />

                        <div className="Create__main-content">
                                <h2>Create a Space/Page</h2>
                                <div className="Create__form-group">
                                        <select
                                                className="form-select Create__input"
                                                aria-label="Default select example"
                                                name="selected_type"
                                                value={selectedType}
                                                onChange={handleSelectedType}
                                                required
                                        >
                                                <option value="">- Select Content Type -</option>
                                                <option value="Space">Space</option>
                                                <option value="Page" disabled={!hasSpaces}>Page</option>
                                        </select>
                                </div>
                                {selectedType === "Page" && (
                                        <div className="Create__form-group">
                                                <label style={{ paddingTop: "3px" }}>Select a parent space or page: </label>
                                                <Select
                                                        options={allDocumentation} 
                                                        value={allDocumentation.find(option => option.value === selectedParent)} 
                                                        onChange={(option) => setSelectedParent(option ? option.label : "")}
                                                        placeholder="Select a Space"
                                                        isSearchable={true}
                                                />
                                        </div>
                                )}
                                <div className="Create__form-group">
                                        <label>Name: </label>
                                        <input
                                                type="text"
                                                id="name"
                                                className="Create__input-short-field"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                maxLength={currentMaxLength}
                                                placeholder={`Max ${currentMaxLength} characters`}
                                                required
                                        />
                                </div>
                                <div className="Create__form-group">
                                        <label>Short Description: </label>
                                        <input
                                                type="text"
                                                id="description"
                                                className="Create__input-full-field"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                        />
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                This description is what invited users will see about 
                                                this space/page.
                                        </p>
                                </div>
                                <div className="Create__form-group">
                                        <label>Make this content public?</label>
                                        <label className="Create__switch">
                                                <input
                                                        type="checkbox"
                                                        checked={isPublic}
                                                        onChange={handleToggle}
                                                />
                                                <span className="Create__slider round"></span>
                                        </label>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Public content is viewable by anyone that has this document's URL. 
                                        </p>
                                </div>
                                <div className="Create__form-group">
                                        <label>Publish this page upon submitting?</label>
                                        <label className="Create__switch">
                                                <input
                                                        type="checkbox"
                                                        checked={completed}
                                                        onChange={handleCompletedToggle}
                                                />
                                                <span className="Create__slider round"></span>
                                        </label>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Whether the document is public or not, this document will not be <strong>viewable</strong> by anyone; including 
                                                added collaborators that have "Can View" permission. Collaborators that have "Can Edit" permission can edit this document.
                                        </p>
                                </div>
                                <div className="Create__form-group" style={{ flexDirection: "column", marginBottom: "60px" }}>
                                        <label>Invite others to collaborate on this content.</label>
                                        {emails.map((item, index) => (
                                                <div key={index} className="Create__email-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <span>{item.email}</span>
                                                        <select
                                                                value={item.permission}
                                                                onChange={(e) => handlePermissionChange(index, e.target.value)}
                                                                style={{ marginLeft: '10px', padding: '5px' }}
                                                        >
                                                                <option value="Can Edit">Can Edit</option>
                                                                <option value="Can View">Can View</option>
                                                                <option value="Admin">Admin</option>
                                                        </select>
                                                        <button onClick={() => handleRemoveEmail(index)} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
                                                                <i className="fas fa-trash-alt" title="Remove Email" style={{ color: "white" }}></i>
                                                        </button>
                                                </div>
                                        ))}     
                                        <div className="Create__email-input-container">
                                                <input
                                                        type="email"
                                                        className="Create__email-input"
                                                        placeholder="Enter E-mail Address"
                                                        value={currentEmail}
                                                        onChange={(e) => setCurrentEmail(e.target.value)}
                                                        onKeyDown={(e) => e.key === "Enter" && handleAddEmail()}
                                                />
                                                <button onClick={handleAddEmail} className="Create__add-email-btn">
                                                        <i className="fas fa-plus"></i>
                                                </button>
                                        </div>
                                        <div className="Create__form-group-helper-text">
                                                <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                                <p className="Create__helper-text">
                                                        As of this current version, any added permissions will <strong>not</strong> automatically be added to any future child documents that are created.
                                                </p>
                                        </div>
                                </div>
                                <div className="Create__editor-div">
                                        <ReactQuill
                                                value={content}
                                                onChange={setContent}
                                                modules={modules}
                                                formats={[
                                                        'header', 'list', 'bold', 'italic', 'underline', 'code-block', 'blockquote',
                                                        'link', 'image', 'video', 'strike', 'color', 'background', 'indent', 'font',
                                                        'size', 'align', 'script', 'direction', 'code', 'inline-code', 'formula'
                                                      ]}
                                                theme="snow"
                                                style={{ height: "70vh" }}
                                        />
                                        {/* <div className="Create__output" dangerouslySetInnerHTML={{ __html: content }}></div> */}
                                </div>
                                <button onClick={handleSubmit} className="submit-button">Submit</button>
                        </div>
              </div>
        )

}

export default Create