import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Sidebar from "../shared/Sidebar";
import "../../css/DocumentationMainPage.css"
import { auth } from "../../firebase.js"
import "../../css/Document.css"
import "../../css/DocumentationHomeComponent.css"
import "../../css/Create.css"
import Comments from "./Comments.js";

function Document() {

        const { type, name, uid } = useParams(); 
        const [userUid, setUserUid] = useState("")
        const [userEmail, setUserEmail] = useState("")
        const [loading, setLoading] = useState(true)
        const [hasPermission, setHasPermission] = useState(true)
        const [documentResponseData, setDocumentResponseData] = useState("")
        const [canEdit, setCanEdit] = useState(true)
        const [parentDocument, setParentDocument] = useState("")
        const [published, setPublished] = useState(true)
        const [shareOnPublicRegistry, setShareOnPublicRegistry] = useState(false)
        const [isShareVisible, setIsShareVisible] = useState(false);
        const navigate = useNavigate()

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                                setUserUid(user.uid);
                                setUserEmail(user.email); 
                        } else {
                                setUserUid(null);
                                setUserEmail(null); 
                                setHasPermission(false)
                                setLoading(false)
                        }
                });
            
                return () => { unsubscribe(); };
        }, []);

        useEffect(() => {
                if (uid && userUid && userEmail) {
                        fetchDocumentContent();
                }
        }, [type, name, uid, userUid, userEmail]);

        const fetchDocumentContent = async () => {
                setHasPermission(true)
                setPublished(true)
                if (!userUid || !userEmail) {
                        setLoading(false)
                        return
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/${type}/${name}/${uid}/`, {
                                method: 'GET',
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                }
                        })

                        setLoading(false)

                        if (response.status === 403) {
                                setHasPermission(false)
                                return
                        }

                        if (response.status === 404) {
                                setPublished(false)
                                return
                        }

                        const data = await response.json()
                        if (data.permissions) {
                                setCanEdit(data.permissions.can_edit);
                        }
                        const updatedContent = transformContent(data.content);
                        setDocumentResponseData({ ...data, content: updatedContent });
                        setParentDocument(data.parent_page)
                } catch (error) {
                        setLoading(false)
                        console.error("Error fetching document data: ", error)
                }
        }
        
        const handleDeleteClick = async () => {
                const isConfirmed = window.confirm("Are you sure you want to delete this document?\n\n-This action cannot be undone.\n-Any child pages will be deleted as well.");
                if (!isConfirmed) {
                        return;
                }
                const data = {
                        parentDocument: parentDocument
                }

                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }

                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/delete/${type}/${name}/${uid}/`, {
                                method: "DELETE",
                                body: JSON.stringify(data),
                                headers: { 
                                        "Content-Type": "application/json",
                                        "auth-token": `Bearer ${token}`,
                                },
                        })

                        if (response.ok) {
                                console.log("Document deleted successfully");
                                navigate("/documentation")
                        } else {
                                console.log("Failed to delete document");
                        }
                } catch (error) {
                        console.error("Error deleting document: ", error);
                }
        }

        const formatTimestamp = (timestamp) => {
                const date = new Date(timestamp);
                return date.toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                });
        };

        const transformContent = (htmlContent) => {
                if (!htmlContent) return "";
        
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, "text/html");
        
                doc.querySelectorAll("h1").forEach((h1) => {
                        const h3 = document.createElement("h3");
                        h3.innerHTML = h1.innerHTML;
                        h1.replaceWith(h3);
                });
        
                doc.querySelectorAll("h2").forEach((h2) => {
                        const h4 = document.createElement("h4");
                        h4.innerHTML = h2.innerHTML;
                        h2.replaceWith(h4);
                });
        
                return doc.body.innerHTML;
        };

        const handleShareOnPublicRegistrySubmit = async () => {
                const data = {
                        shareOnPublicRegistry: shareOnPublicRegistry
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/delete/${type}/${name}/${uid}/`, {
                                method: "POST",
                                body: JSON.stringify(data),
                                headers: { "Content-Type": "application/json" },
                        })

                        if (response.ok) {
                                console.log("Submitted public registry status successfully.")
                        } else {
                                console.error("Error in submitting public registry status.")
                        }
                } catch (error) {
                        console.error("Error in submitting public registry status.")
                }
        }

        function capitalizeFirstLetter(val) {
                return String(val).charAt(0).toUpperCase() + String(val).slice(1);
        }

        const handleShareClick = () => {
                setIsShareVisible(!isShareVisible)
        }

        const toggleShareOnPublicRegistry = (e) => setShareOnPublicRegistry(e.target.checked);

        const link = "https://bluebird-documentation.com/documentation/{type}/{name}/{uid}";

        const copyToClipboard = () => {
                navigator.clipboard.writeText(link);
                alert("Link copied to clipboard!");
        };

        if (loading) {
                return (
                        <div className="DocumentationHomeComponent__loading-animation-div">
                                <i className="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i>
                        </div>
                )
        }

        if (loading === false && hasPermission === false) {
                return (
                        <div className="DocumentationMainPage__div">
                                <Sidebar />
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks like you don't have permission to view this document.</p>
                                        <p className="medium">
                                                <strong>You might just need to sign in</strong>. If this is your document, make sure you're signed in. If 
                                                this issue persists, this could be a bug on our end. We are very sorry for the inconvenience; please let us know 
                                                this has happened.
                                        </p>
                                        <p className="medium">
                                                <strong>If this is not your document</strong>, check with the original author of the document to see if you have correct 
                                                permissions.
                                        </p>
                                </div>
                        </div>
                )
        }

        if (loading === false && published === false) {
                return (
                        <div className="DocumentationMainPage__div">
                                <Sidebar />
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks you have permission to view this document, but is has not been published yet.</p>
                                        <p className="medium">
                                                <strong>As of the current version</strong>, only collaborators that have "Can Edit" permission can view/edit documents that 
                                                are not published yet. Otherwise, once the document is marked as published, you will be able to view the document.
                                        </p>
                                </div>
                        </div>
                )
        }

        return (
                <div className="DocumentationMainPage__div">
                        <Sidebar />

                        <div className="Document__floating-div">
                                {documentResponseData.is_public ? (
                                        <i class="fa-solid fa-share Document__icon-padding" title="Share" onClick={handleShareClick} style={{ marginRight: "8px", cursor: "pointer" }}></i>
                                ) : (
                                        <></>
                                )}
                                {canEdit ? (
                                        <Link to={`/documentation/${type}/${name}/${uid}/edit`} className="Document__a">
                                                <i className="fas fa-edit Document__icon-padding" title="Edit" style={{ marginRight: "8px", cursor: "pointer" }}></i>
                                        </Link>
                                ) : (
                                        <i className="fas fa-lock Document__icon-padding" title="Can't Edit" style={{ marginRight: "8px", cursor: "not-allowed" }}></i>
                                )}
                                {uid === userUid ? (
                                        <i className="fas fa-trash Document__icon-padding" title="Delete Document" style={{ marginRight: "8px", cursor: "pointer" }} onClick={handleDeleteClick}></i>
                                ) : (
                                        <i className="fas fa-lock Document__icon-padding" title="Can't Delete Document" style={{ marginRight: "8px", cursor: "not-allowed" }}></i>
                                )}
                                <div className="Document__icon-separator"></div>
                                {documentResponseData.is_public ? (
                                        <i className="fas fa-eye Document__icon-padding" title="Document is Public" style={{ cursor: "pointer" }}></i>
                                ) : (
                                        <i className="fas fa-eye-slash Document__icon-padding" title="Document is not Public" style={{ cursor: "pointer" }}></i>
                                )}
                                {documentResponseData.completed ? (
                                        <i className="fa-solid fa-circle-check Document__icon-padding" title="Published" style={{ cursor: "pointer" }}></i>
                                ) : (
                                        <i className="fa-solid fa-circle-xmark Document__icon-padding" title="Not Published" style={{ cursor: "pointer" }}></i>
                                )}
                        </div>

                        {isShareVisible && (
                                <div className="Document__overlay">
                                        <div className="Document__popup">
                                                <h2>Share {capitalizeFirstLetter(type)}</h2>
                                                {type === "space" && userUid === uid ? (
                                                        <>
                                                                <p>Would you like this space to appear on our public registry? Anyone can search for it from there.</p>
                                                                <label className="Create__switch">
                                                                        <input 
                                                                                type="checkbox"
                                                                                checked={shareOnPublicRegistry}
                                                                                onChange={toggleShareOnPublicRegistry}
                                                                        />
                                                                        <span className="Create__slider round"></span>
                                                                </label>
                                                        </>
                                                ) : userUid === uid ? (
                                                        <>
                                                                <p>Would you like this page to appear on our public registry? Anyone can search for it from there.</p>
                                                                <label className="Create__switch">
                                                                        <input 
                                                                                type="checkbox"
                                                                                checked={shareOnPublicRegistry}
                                                                                onChange={toggleShareOnPublicRegistry}
                                                                        />
                                                                        <span className="Create__slider round"></span>
                                                                </label>
                                                        </>
                                                ) : (
                                                        <></>
                                                )}
                                                <p>To share a link of this single document, you can copy the link below.</p>
                                                <div className="Document__shareable-link-container">
                                                        <p><span role="textbox" aria-label="Shareable link" className="Document__share-link">https://bluebird-documentation.com/documentation/{type}/{name}/{uid}</span></p>
                                                        <i
                                                                className="fa-solid fa-copy Document__copy-icon"
                                                                onClick={copyToClipboard}
                                                                title="Copy to clipboard"
                                                        ></i>
                                                </div>
                                                <button className="Document__modal-close-button" onClick={handleShareClick}>Close</button>
                                                <button className="Document__modal-submit-button" onClick={handleShareOnPublicRegistrySubmit}>SUBMIT</button>
                                        </div>
                                </div>
                        )}

                        <div className="DocumentationMainPage__main-content">

                                <div className="Document__div">

                                        <h2>{name}</h2>

                                        <p className="extra-small" style={{ paddingTop: "30px" }}><span className="semi-bold">Created At:</span> <i>{formatTimestamp(documentResponseData.created_at)}</i></p>
                                        <p className="extra-small" style={{ paddingBottom: "30px" }}><span className="semi-bold">Last Updated At:</span> <i>{formatTimestamp(documentResponseData.updated_at)}</i></p>

                                        <div className="ql-editor ql-editor-styling" dangerouslySetInnerHTML={{ __html: documentResponseData.content }} />

                                </div>

                                <Comments
                                        type={type}
                                        name={name}
                                        uid={uid}
                                        userEmail={userEmail}
                                        userUid={userUid}
                                />

                        </div>

                </div>
        )

}

export default Document